import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <div id="wordmark"><img id="logo" src="/frostyvoid-logo-small.png" alt="FrostyVoid Inc. Logo"/>FrostyVoid Inc.</div>
      </header>
      <section className="main">
        <div className="content-wrapper">
          <h1>We Love Video Games</h1>
          <div className="intro">FrostyVoid builds gaming communities that we want to be a part of. Our team is passionate about curating and creating engaging content for enthusiastic gamers.</div>
        </div>
      </section>
      <section className="site-section site-outofcards">
        <div className="content-wrapper">
          <div class="logo"><a href="https://outof.cards" title="Visit Out of Cards"><img width="320" height="149" src="/outofcards-logo.png" alt="Out of Cards Logo"/></a></div>
          <div class="description">
            <h2><a href="https://outof.cards" title="Visit Out of Cards">Out of Cards</a></h2>
            <div>Out of Cards is the premiere site for card gamers. News, databases, and deckbuilders brings fans of the card game genre one-stop for the tools they need to succeed.</div>
          </div>
        </div>
      </section>
      <section className="site-section site-outofgames">
        <div className="content-wrapper">
          <div class="logo"><a href="https://outof.games" title="Visit Out of Games"><img width="320" height="149" src="/outofgames-logo.png" alt="Out of Games Logo"/></a></div>
          <div class="description">
            <h2><a href="https://outof.games" title="Visit Out of Games">Out of Games</a></h2>
            <div>Out of Games was born out of a desire to create general engaging content tailored to gamers. Too many of the original gaming websites have moved away from being just about games and that needed to be fixed.</div>
          </div>
        </div>
      </section>
      <footer>
        <div>Copyright &copy; FrostyVoid Inc. All rights reserved.</div>
      </footer>
    </div>
  );
}

export default App;
